/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Bounce } from "react-awesome-reveal";

function BuiltByDevelopers() {
  const bgImage =
    "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/desktop.jpg";

  return (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={2}
      py={6}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.8),
            rgba(gradients.dark.state, 0.8)
          )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Grid container item xs={12} lg={6} sx={{ ml: { xs: 0, lg: 6 } }}>
          <Bounce>
            <MKTypography variant="h1" color="white" mb={1}>
              Notre Expertise
            </MKTypography>
            <MKTypography variant="h4" color="white" fontWeight="bold">
              PRODIGITAL est un réservoir d’experts
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8} mb={2}>
              PRODIGITAL est un réservoir d’experts du futur hautement qualifiés, certifiés par des
              fournisseurs reconnus allant des compétences techniques à la gestion de projets
              complexes à l’optimisation des processus :{" "}
              <b>SOPHOS, CISCO VMWARE, LEAN SIX SIGMA PMI</b> et E&PNos . Nos collaborateurs œuvrent
              main dans la main avec nos managers et nos clients pour fournir des solutions
              efficaces qui propulseront leurs entreprises vers de nouveaux sommets.
              <br />
              Les équipes de <b>PRODIGITAL</b> capitalisent une expertise approuvée dans les
              secteurs Oil & Gas, cœur de nos métiers. Grâce à notre expertise internationale,notre
              compréhension du contexte l’agilité de notre organisation, nous nous engageons à
              fournir des solutions d’excellence et personnalisées accompagnées de services de
              qualité, fiables et ponctuels.
            </MKTypography>
            <MKTypography
              component="a"
              href="#"
              target="_blank"
              rel="noreferrer"
              variant="body2"
              color="white"
              fontWeight="regular"
              sx={{
                display: "flex",
                alignItems: "center",

                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: `translateX(3px)`,
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },

                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: `translateX(6px)`,
                },
              }}
            >
              Lire plus <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </MKTypography>
          </Bounce>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default BuiltByDevelopers;

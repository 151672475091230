/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import Typography from "@mui/material/Typography";
// import MKTypography from "components/MKTypography";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";
import { Fade } from "react-awesome-reveal";

// import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import expertise from "../../../assets/images/block/business_07.png";
import creative from "../../../assets/images/block/business_22.png";
import pertinence from "../../../assets/images/block/business_18.png";
// ref slide images
// import ref from "../../../assets/images/logos/ref/adex.jpg";
// import ref1 from "../../../assets/images/logos/ref/hello-pomelo.jpg";
// import ref2 from "../../../assets/images/logos/ref/kyo.png";
// import ref3 from "../../../assets/images/logos/ref/smartest.jpg";
// import ref4 from "../../../assets/images/logos/ref/logo-dypix.jpg";

function Counters() {
  return (
    <>
      <MKBox component="section" py={3} mt={4}>
        <Container>
          <Fade>
            <Grid
              container
              item
              xs={12}
              lg={9}
              sx={{ mx: "auto" }}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} md={4} px={2} mt={3}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={expertise} alt="prodigital Expertise" />
                </div>
                <Typography variant="span" style={{ textAlign: "center" }} component="h5" my={1}>
                  Expertise
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: "#7b809a", fontWeight: "normal", textAlign: "center" }}
                  gutterBottom
                >
                  Opter pour les outils efficaces et pertinents pour atteindre vos objectifs
                  marketing
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} px={2} mt={3}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={creative} alt="prodigital Créativité" />
                </div>
                <Typography variant="span" style={{ textAlign: "center" }} component="h5" my={1}>
                  Créativité
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: "#7b809a", fontWeight: "normal", textAlign: "center" }}
                  gutterBottom
                >
                  Vous avez une idée, nous imaginons et nous impliquons pour cerner et définir vos
                  stratégies de communication
                </Typography>
                <Divider
                  orientation="vertical"
                  sx={{ display: { xs: "none", md: "block" }, mx: 0 }}
                />
              </Grid>
              <Grid item xs={12} md={4} px={2} mt={3}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={pertinence} alt="prodigital web design" />
                </div>
                <Typography variant="span" style={{ textAlign: "center" }} component="h5" my={1}>
                  Pertinence
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: "#7b809a", fontWeight: "normal", textAlign: "center" }}
                  gutterBottom
                >
                  Les solutions créatives et innovantes pour singulariser votre communication, et
                  vous démarquer de la concurrence
                </Typography>
              </Grid>
              {/* <Grid item xs={12} md={4} display="flex">
              <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
              <DefaultCounterCard
                count={15}
                suffix="+"
                title="Design Blocks"
                description="Mix the sections, change the colors and unleash your creativity"
              />
              <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
            </Grid>
            <Grid item xs={12} md={4}>
              <DefaultCounterCard
                count={4}
                title="Pages"
                description="Save 3-4 weeks of work when you use our pre-made pages for your website"
              />
            </Grid> */}
            </Grid>
          </Fade>
        </Container>
      </MKBox>
      <MKBox component="section" py={3}>
        {/* <Container>
          <Grid
            container
            item
            xs={12}
            lg={10}
            sx={{ mx: "auto" }}
            justifyContent="center"
            alignItems="center"
          >
            <Bounce triggerOnce>
              <MKTypography variant="h2" mb={3}>
                Ils nous ont fais confiance
              </MKTypography>
            </Bounce>
            <Carousel showThumbs={false} infiniteLoop emulateTouch swipeable show={3} autoPlay>
              <Grid
                container
                spacing={5}
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "25vh", textAlign: "left" }}
                mb={3}
              >
                <Grid item xs={3}>
                  <img src={ref} alt="prodigital web design" />
                </Grid>
                <Grid item xs={3}>
                  <img src={ref1} alt="prodigital web design" />
                </Grid>
                <Grid item xs={3}>
                  <img src={ref2} alt="prodigital web design" />
                </Grid>
                <Grid item xs={3}>
                  <img src={ref3} alt="prodigital web design" />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={5}
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "25vh", textAlign: "left" }}
              >
                <Grid item xs={3}>
                  <img src={ref4} alt="prodigital web design" />
                </Grid>
              </Grid>
            </Carousel>
          </Grid>
        </Container> */}
      </MKBox>
    </>
  );
}

export default Counters;

/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
// import Pages from "pages/Presentation/sections/Pages";
// import Testimonials from "pages/Presentation/sections/Testimonials";
import Download from "pages/Presentation/sections/Download";

// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";
import { Slide } from "react-awesome-reveal";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation.jpg";
import MainCarousel from "components/MKSlidShow/MainCarousel";

function Presentation() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "tel:+213 0558 21 50 91",
          label: "+213 0558 21 50 91",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="50vh"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container sx={{ my: 10 }} style={{ width: "100vw" }}>
          <MainCarousel />
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <Information />
        <DesignBlocks />
        {/* <Pages /> */}
        <Container sx={{ mt: 6 }}>
          <BuiltByDevelopers />
        </Container>
        <Container name="Developpement web">
          <Grid container spacing={3} mt={3}>
            <Grid item xs={12} lg={4}>
              <Slide direction="left" triggerOnce>
                <FilledInfoCard
                  variant="gradient"
                  color="info"
                  icon="flag"
                  title="DEVELPPEMENT WEB"
                  description="Avec le développement de l’utilisation d’internet, avoir un site internet est devenu une nécessité pour chaque commune. Pouvoir présenter ses services, les activités proposées, les plateformes digitales se développent et deviennent indispensables."
                  action={{
                    type: "external",
                    route: "#",
                    label: "Let's start",
                  }}
                />
              </Slide>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Slide direction="up" triggerOnce>
                <FilledInfoCard
                  color="info"
                  icon="precision_manufacturing"
                  title="APPLICATION MOBILE"
                  description="Quelle que soit la technologie choisie, nous vous livrons une application mobile de haute qualité et clé en main.
                  Après le lancement de l’application, nous continuons de vous accompagner et de vous conseiller en cas de souhaits d’évolution ou de bugs.
                  ."
                  action={{
                    type: "external",
                    route: "#",
                    label: "lire plus",
                  }}
                />
              </Slide>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Slide direction="right" triggerOnce>
                <FilledInfoCard
                  color="info"
                  icon="apps"
                  title="AUDIOVISUEL ET EVEMENTIEL"
                  description="Plus qu’une simple boite événementielle, PRODIGITAL est une source d’idées originales et de prestations de qualité. PRODIGITAL prend en charge vos projets audiovisuelles, de la conception au tournage, en passant par le montage jusqu’à la diffusion de votre vidéo."
                  action={{
                    type: "external",
                    route: "#",
                    label: "En svoire plus",
                  }}
                />
              </Slide>
            </Grid>
          </Grid>
        </Container>
        {/* <Testimonials /> */}
        <Download />
        <MKBox pt={8} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Thank you for your support!
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  We deliver the best web products
                </MKTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MKSocialButton
                  component="a"
                  href="#"
                  target="_blank"
                  color="twitter"
                  sx={{ mr: 1, mt: 2 }}
                >
                  <i className="fab fa-twitter" />
                  &nbsp;Tweet
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="#"
                  target="_blank"
                  color="facebook"
                  sx={{ mr: 1, mt: 2 }}
                >
                  <i className="fab fa-facebook" />
                  &nbsp;Share
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="#"
                  target="_blank"
                  color="pinterest"
                  sx={{ mt: 2 }}
                >
                  <i className="fab fa-pinterest" />
                  &nbsp;Pin it
                </MKSocialButton>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;

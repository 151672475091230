/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Tooltip from "@mui/material/Tooltip";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/shapes/waves-white.svg";

function Download() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 8 }}>
      <MKBox
        variant="gradient"
        bgColor="dark"
        position="relative"
        borderRadius="xl"
        sx={{ overflow: "hidden" }}
      >
        <MKBox
          component="img"
          src={bgImage}
          alt="pattern-lines"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          zIndex={1}
          opacity={0.2}
        />
        <Container sx={{ position: "relative", zIndex: 2, py: 8 }} name="Erp">
          <Grid container item xs={12} md={7} justifyContent="center" mx="auto" textAlign="center">
            <MKTypography variant="h2" color="white">
              Progiciels de gestion integré
            </MKTypography>
            <MKTypography variant="h4" color="white" mb={1}>
              Besoin d&apos;une solution IT (CRM et ERP)
            </MKTypography>
            <MKTypography variant="body1" color="white" mb={4}>
              Achats, Stock / WMS, C.R.M, Ventes, Facturation, S.A.V, Production, MRP, Maintenance /
              GMAO, Qualité, P.L.M, Planification / MES, Comptabilité générale, Comptabilité
              analytique, Budgétaire & Prévision, Trésorerie, Télédeclaration & Etats, Projet /
              Chantier, RH & Paie, Parc Auto, POS / Restaurant, E-commerce, Appel d&apos;offres
            </MKTypography>
            <MKButton
              variant="gradient"
              color="info"
              size="large"
              component="a"
              href="#"
              sx={{ mb: 2 }}
            >
              Commancer toute de suite
            </MKButton>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default Download;

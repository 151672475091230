/* eslint-disable prettier/prettier */
import React, { useState } from 'react';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SlideDialog from "../../examples/Navbars/SlideDialog";
import web from '../../assets/slide/webDesign.webp';
import branding from '../../assets/slide/branding.png';
import erp from '../../assets/slide/erp.png';

 const MainCarousel = () => {
    const [isAutoPlay, setIsAutoPlay] = useState(true);
    
        return (
            <Carousel onMouseEnter={() => setIsAutoPlay(false)}
            onMouseLeave={() => setIsAutoPlay(true)}  showThumbs={false} axis="horizontal" width="100%" emulateTouch swipeable infiniteLoop autoPlay={isAutoPlay}>
              <Box sx={{ flexGrow: 1 }}  py={4}>
                <Grid container spacing={5} justifyContent="center" alignItems="center" style={{ minHeight:"50vh", textAlign:'left' }} >
                  <Grid item xs={12} md={7}>
                      <Typography variant="h1" component="h1" style={{color:"#fff"}} my={2}>Digne de confiance</Typography>
                      <Typography variant="h4" component="div" style={{color:"#fff", fontWeight:'normal'}} >
                        Vous pouvez vous attendre à de la prévenance et de l&apos;attention dans tout ce que nous faisons. Nous allons trouver la manière la plus efficace, la plus percutante et, espérons-le, la plus significative de faire avancer votre Business.
                        </Typography>
                        
                  </Grid>
                  <Grid item xs={12} md={5} >
                    <SlideDialog />
                  </Grid>
                </Grid>
              </Box>
              
              <Box sx={{ flexGrow: 1 }}  py={4}>
                <Grid container spacing={1} justifyContent="center" alignItems="center" style={{ minHeight:"50vh", textAlign:'left' }} >
                  <Grid item xs={12} md={7}>
                      <Typography variant="h1" component="h1" style={{color:"#fff"}} my={2}>CRM et ERP</Typography>
                      <Typography variant="span" component="h1" style={{color:"#fff"}} mt={2}>Progiciels de gestion integré</Typography>
                      <Typography variant="h4" component="div" style={{color:"#fff", fontWeight:'normal'}} >
                      Ventes, relation client (CRM), projets, logistique,
achats, importation, stocks et entrepôts, production (GPAO), maintenance (GMAO), comptabilité, finances, paie, RH et combien d’autres
tâches !
                        </Typography>
                        
                  </Grid>
                  <Grid item xs={12} md={5} >
                    <div>
                      <img src={erp} alt="prodigital web design" />
                    </div>
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{ flexGrow: 1 }}  py={4}>
                <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ minHeight:"50vh", textAlign:'left' }} >
                  <Grid item xs={12} md={7}>
                      <Typography variant="h1" component="h1" style={{color:"#fff"}} my={2}>Développement web</Typography>
                      <Typography variant="span" component="h1" style={{color:"#fff"}} mt={2}>Responsive web design</Typography>
                      <Typography variant="h4" component="div" style={{color:"#fff", fontWeight:'normal'}} >
                      Contrairement aux autres médias, le Web est accessible de partout à travers le monde, 7/7 24/24 en offrant une présence permanente.
Le site web est aussi pour beaucoup de clients potentiels, un gage de confiance. Il rassure sur la qualité des prestations et des produits fournis.

                        </Typography>
                        
                  </Grid>
                  <Grid item xs={12} md={5} >
                    <div>
                      <img src={web} alt="prodigital web design" />
                    </div>
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{ flexGrow: 1 }}  py={4}>
                <Grid container spacing={1} justifyContent="center" alignItems="center" style={{ minHeight:"50vh", textAlign:'left' }} >
                  <Grid item xs={12} md={7}>
                      <Typography variant="h1" component="h1" style={{color:"#fff"}} my={2}>Branding</Typography>
                      <Typography variant="span" component="h1" style={{color:"#fff"}} my={2}>Design graphique et identité visuelle</Typography>
                      <Typography variant="h4" component="div" style={{color:"#fff", fontWeight:'normal'}} >
                      Grâce à notre savoir-faire en création et design
                      graphique,nous saurons mettre en forme et en
                      couleurs la stratégie de communication de
                      l’entreprise.
                      </Typography>      
                  </Grid>
                  <Grid item xs={12} md={5} p={0} >
                    <div>
                      <img src={branding} alt="prodigital brading, graphic design" />
                    </div>
                  </Grid>
                </Grid>
              </Box>
                
            </Carousel>
        )

};

export default MainCarousel;
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
// import logoCT from "assets/images/logo-ct-dark.png";
import logoPro from "assets/images/logo.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Prodigital",
    image: logoPro,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/Prodigital.dz/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/Prodigital.dz",
    },
    {
      icon: <InstagramIcon />,
      link: "https://github.com/Prodigital.dz",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/Prodigital.dz",
    },
  ],
  menus: [
    {
      name: "company",
      items: [
        { name: "A propos", href: "https://www.prodigital.dz/presentation" },
        { name: "Notre metier", href: "#" },
        { name: "Notre mission", href: "#" },
        { name: "blog", href: "#" },
      ],
    },
    {
      name: "Développement web",
      items: [
        { name: "Site vitrine", href: "#" },
        { name: "Site e-commerce", href: "#" },
        { name: "Site sur mesure", href: "#" },
      ],
    },
    {
      name: "Solutions IT",
      items: [
        { name: "CRM", href: "#" },
        { name: "ERP", href: "#" },
        { name: "CMS", href: "#" },
      ],
    },
    {
      name: "Mension legal",
      items: [
        { name: "terms & conditions", href: "#" },
        { name: "privacy policy", href: "#" },
        { name: "contact", href: "#" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} <b>PRODIGITAL</b> by{" "}
      <MKTypography
        component="a"
        href="#"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Prodigital DevTeam
      </MKTypography>
      .
    </MKTypography>
  ),
};

import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import MKButton from "components/MKButton";
import { CircularProgress } from "@mui/material";
import Alert from "@mui/material/Alert";

export default function FullWidthTextField(props) {
  const { handleClose } = props;
  const [isSending, setIsSending] = React.useState(false);
  const [Sending, setSending] = React.useState(false);
  const [formState, setformState] = React.useState({
    name: "",
    company: "",
    departement: "non spécifié",
    email: "",
    phone: "",
    message: "Des détails à ajouter ?",
    emailStatus: "",
  });
  const currencies = [
    {
      value: "non spécifié",
      label: "Choisir..",
    },
    {
      value: "des",
      label: "Branding",
    },
    {
      value: "dev",
      label: "Développement web",
    },
    {
      value: "app",
      label: "Application mobile",
    },
    {
      value: "cms",
      label: "CRM et ERP",
    },
    {
      value: "avd",
      label: "Audiovisuel et événementiel",
    },
  ];
  const [currency, setCurrency] = React.useState("non spécifié");
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (Sending) {
        setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
      }
    }, 200);

    return () => {
      clearInterval(timer);
    };
  }, [Sending]);

  const handleChangeDep = (event) => {
    setCurrency(event.value);
    setformState({
      ...formState,
      departement: event.value,
    });
  };

  // handle the value
  const handleCHange = (input) => {
    setformState({
      ...formState,
      [input.name]: input.value,
    });
  };
  const ValidateEmail = (input) => {
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (input.match(validRegex)) {
      return true;
    }
    return false;
  };
  // when submit btn is clicked
  const submitForm = (e) => {
    e.preventDefault();
    setIsSending(true);
    // create a new XMLHttpRequest
    const xhr = new XMLHttpRequest();

    if (
      formState.company.length === 0 ||
      formState.name.length === 0 ||
      formState.departement.length === 0 ||
      formState.email.length === 0 ||
      formState.phone.length === 0 ||
      !ValidateEmail(formState.email) ||
      formState.message.length === 0
    ) {
      return false;
    }
    setSending(true);
    // get a callback when the server responds
    xhr.addEventListener("load", () => {
      // update the response state and the step
      setformState({
        ...formState,
        emailStatus: xhr.responseText,
      });
    });
    // open the request with the verb and the url
    xhr.open(
      "GET",
      `https://prodigital.dz/mail.php?sendto=${formState.email} 
      &name=${formState.name} 
      &phone=${formState.phone}
      &message= ${formState.message}
      &company=${formState.company}
      &departement=${formState.departement}`
    );
    // send the request
    xhr.send();

    // reset the fields
    setIsSending(false);
    setformState({
      name: "",
      company: "",
      departement: "non spécifié",
      email: "",
      phone: "",
      message: "Des détails à ajouter ?",
      emailStatus: "",
    });
    setSending(false);
    return true;
  };

  return (
    <Box
      sx={{
        width: 500,
        maxWidth: "100%",
      }}
    >
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "100%" },
        }}
        noValidate
        autoComplete="off"
        px={4}
        onSubmit={submitForm}
      >
        <Typography variant="h5" component="div" style={{ color: "#2b2b2b" }} my={2}>
          Commancer maintenant
        </Typography>

        <div>
          <TextField
            error={isSending && formState.company === ""}
            helperText={isSending && formState.company === "" ? "Ce champ est requis!" : " "}
            fullWidth
            name="company"
            value={formState.company}
            onChange={(e) => handleCHange(e.target)}
            label="Nom d'entreprise"
            id="fullWidth"
            style={{ marginTop: 0, marginBottom: 0 }}
            size="small"
            required
          />
        </div>
        <div>
          <TextField
            error={isSending && formState.name === ""}
            helperText={isSending && formState.name === "" ? "Ce champ est requis!" : " "}
            fullWidth
            label="nom complet"
            id="fullName"
            style={{ marginTop: 0, marginBottom: 0 }}
            size="small"
            name="name"
            value={formState.name}
            onChange={(e) => handleCHange(e.target)}
            required
          />
        </div>
        <div>
          <TextField
            error={isSending && (formState.email === "" || !ValidateEmail(formState.email))}
            helperText={
              isSending && (formState.email === "" || !ValidateEmail(formState.email))
                ? "Une addresse mail valide est requise !"
                : " "
            }
            fullWidth
            type="email"
            label="email"
            id="email"
            style={{ marginTop: 0, marginBottom: 0 }}
            size="small"
            name="email"
            value={formState.email}
            onChange={(e) => handleCHange(e.target)}
            required
          />
        </div>
        <div>
          <TextField
            error={isSending && formState.phone === ""}
            helperText={
              isSending && formState.phone === "" ? "Un numéro de téléphone est requis !" : " "
            }
            fullWidth
            type="phone"
            label="téléphone"
            id="phone"
            style={{ marginTop: 0, marginBottom: 0 }}
            size="small"
            name="phone"
            value={formState.phone}
            onChange={(e) => handleCHange(e.target)}
            required
          />
        </div>
        <div>
          <TextField
            id="standard-select-currency"
            select
            label="service souhaité"
            value={currency}
            onChange={(e) => handleChangeDep(e.target)}
            helperText="Please select your currency"
            variant="standard"
            style={{ textAlign: "left", marginTop: 0, marginBottom: 0 }}
            name="departement"
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div>
          <TextField
            error={
              isSending &&
              (formState.message === "" || formState.message === "Des détails à ajouter ?")
            }
            helperText={
              isSending &&
              (formState.message === "" || formState.message === "Des détails à ajouter ?")
                ? "Ce champ est requis!"
                : " "
            }
            id="outlined-multiline-static"
            label="Votre besoin"
            multiline
            rows={4}
            name="message"
            value={formState.message}
            onChange={(e) => handleCHange(e.target)}
            required
          />
        </div>
        <div>
          <MKButton variant="gradient" color="info" type="submit">
            {Sending ? (
              <CircularProgress variant="determinate" color="white" size="small" value={progress} />
            ) : (
              ""
            )}
            Envoyer le Message
          </MKButton>
          <MKButton onClick={handleClose}>Pas maintenant</MKButton>
        </div>
        <div>
          {formState.emailStatus.length > 0 && <Alert severity="success">Message envoyé</Alert>},
        </div>
      </Box>
    </Box>
  );
}

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";

import { styled } from "@mui/material/styles";

import Paper from "@mui/material/Paper";
import FormCarousel from "./FormCarousel";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function AlertDialogSlide() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  return (
    <div style={{ textAlign: "center" }}>
      <Button size="large" variant="outlined" onClick={handleClickOpen}>
        Lancer votre projet
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        direction="right"
      >
        <DialogContent>
          <Item style={{ border: ".6rem solid #e0e0e0" }} sx={{ borderRadius: "16px" }}>
            <FormCarousel handleClose={handleClose} />
          </Item>
        </DialogContent>
      </Dialog>
    </div>
  );
}

/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import { Bounce, Slide } from "react-awesome-reveal";
// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <Bounce triggerOnce>
              <RotatingCard>
                <RotatingCardFront
                  image={bgFront}
                  icon="touch_app"
                  title={
                    <>
                      Business
                      <br />
                      Digitalisation
                    </>
                  }
                  description="Partout et en tout temps, PRODIGITAL combine les besoins réels du business avec les solutions digitales intelligentes les plus pertinentes ."
                />
                <RotatingCardBack
                  image={bgBack}
                  title="Inspirer votre transformation"
                  description="Parce que chaque secteur est particulier, chaque entreprise est unique."
                  action={{
                    type: "internal",
                    route: "/sections/page-sections/page-headers",
                    label: "Start now",
                  }}
                />
              </RotatingCard>
            </Bounce>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Slide direction="left" triggerOnce>
                  <DefaultInfoCard
                    icon="content_copy"
                    title="LE VIRAGE NUMÉRIQUE, C’EST MAINTENANT !"
                    description="La digitalisation des entreprises consiste à prendre un virage numérique dans la stratégie et l’organisation interne de votre société."
                  />
                </Slide>
              </Grid>
              <Grid item xs={12} md={6}>
                <Slide direction="right" triggerOnce>
                  <DefaultInfoCard
                    icon="flip_to_front"
                    title="LES BÉNÉFICES DU DIGITAL POUR LES ENTREPRISES"
                    description="Internet vous offre la possibilité de faire du business avec le monde entier ! La condition est d’être présent sur les canaux webmarketing : site web, réseaux sociaux, emailing etc."
                  />
                </Slide>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <Slide direction="up" triggerOnce>
                  <DefaultInfoCard
                    icon="price_change"
                    title="Save Time & Money"
                    description="Seulement 16 % des entreprises disposent d’un e-commerce et proposent la vente en ligne de leurs produits ou services. Avant Internet, vous aviez à votre disposition des moyens limités pour atteindre une cible locale (publicités, flyers, portes ouvertes, publicité TV, etc.)"
                  />
                </Slide>
              </Grid>
              <Grid item xs={12} md={6}>
                <Slide direction="up" triggerOnce>
                  <DefaultInfoCard
                    icon="devices"
                    title="Aujourd’hui,"
                    description="Face à toutes ces nouveautés, il est important pour les entreprises de prendre le virage numérique rapidement pour ne pas se laisser distancer."
                  />
                </Slide>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
